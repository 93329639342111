/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { CiLogin, CiShop } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "reactstrap";
import "./index.css";
import { Link } from "react-router-dom";
import { _post } from "../utils/Helper";
import { SET_USER } from "../redux/action/type";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { X } from "lucide-react";

export default function Login({ setType, toggle, onLoginSuccess }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const obj = { email: form.email, password: form.password };

    _post(
      "api/users/login",
      obj,
      (res) => {
        let data = res?.userDetails.id;
        let token = res?.token;
        data = data.replace(/"/g, "");
        localStorage.setItem("@@toke_$$_45598", JSON.stringify(data));
        localStorage.setItem("@@toke_$$", JSON.stringify(token));
        if (res.role === "user") {
          dispatch({ type: SET_USER, payload: res.userDetails });
          Swal.fire({
            icon: "success",
            title: "Account Created",
            text: "Logged Successfully!",
            confirmButtonText: "OK",
          }).then(() => toggle());
          setLoading(false);
          onLoginSuccess();
        } else {
          Swal.fire({
            icon: "success",
            title: "Failed to Login",
            text: "User not found",
            confirmButtonText: "OK",
          });
          alert("User not found");
        }
      },
      (err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to Login",
          text: "UserName or Password is incorrect",
          confirmButtonText: "OK",
        }).then(() => toggle());
        console.log(err);
      }
    );
  };

  return (
    <Form style={{ padding: "none" }} onSubmit={handleSubmit}>
      <X
        onClick={toggle}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
          float: "right",
          cursor: "pointer",
        }}
      />
      <div className="singIn">
        <h1 className="SingUp-first-header">Login</h1>
        <div className="input-div">
          <Input
            onChange={handleChange}
            name="email"
            className="SingUp-first-input"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="input-div position-relative">
          <Input
            onChange={handleChange}
            name="password"
            className="SingUp-first-input"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
          />
          <span
            className="position-absolute end-0 top-0 mt-2 me-3"
            style={{ cursor: "pointer" }}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label className="labe-for-sign">
            <Input type="checkbox" className="labe-for-sign2" />
            <span>Remember me</span>
          </label>
          <a href="#" className="href">
            Forget password?
          </a>
        </div>
        <Button
          type="submit"
          className="btn btn-for-signUp2"
          disabled={loading}
        >
          <CiLogin style={{ marginRight: 5 }} size={24} className="fw-bold" />{" "}
          {loading && <span className="spinner-border spinner-border-sm" />}
          Login
        </Button>
        <p className="text-center">- OR -</p>
        <Link
          to={"https://app.kasuwamall.com/"}
          className="btn btn-for-signUp2"
        >
          <CiShop style={{ marginRight: 5 }} size={24} className="fw-bold" />{" "}
          Login as Seller
        </Link>
        <div
          className="d-flex justify-content-center"
          style={{ textAlign: "center", marginTop: 15 }}
        >
          <p className="me-2">New user?</p>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              setType("Signup");
            }}
          >
            Sign Up Here
          </a>
        </div>
      </div>
    </Form>
  );
}
