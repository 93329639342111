import React, { useEffect, useState } from 'react';
import "./AppPopup.css"; // Optional CSS for styling

function AppPopup() {
    const [isMobile, setIsMobile] = useState(false); // To detect mobile view
    const [showPopup, setShowPopup] = useState(false); // To control popup visibility

    useEffect(() => {
        // Check if screen width indicates a mobile device
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };
        handleResize(); // Run initially
        window.addEventListener("resize", handleResize); // Listen for screen resize

        // Check if the app popup was previously dismissed or installed
        const isAppInstalled = localStorage.getItem("appInstalled");
        if (!isAppInstalled) {
            // Only show popup if the app is not installed
            setShowPopup(true);
        }

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Handle the Install button click
    const handleInstall = () => {
        // Simulate app installation or redirect to app store
        window.open("https://kasuwamall.com/kasuwa_mobile.apk", "_blank"); // Replace with your app link
        localStorage.setItem("appInstalled", "true"); // Mark as installed
        setShowPopup(false); // Hide the popup
    };

    // Handle the Close button click
    const handleClose = () => {
        localStorage.setItem("appInstalled", "true"); // Mark as dismissed
        setShowPopup(false);
    };

    // **Handle PWA Installation Detection (Optional)**
    useEffect(() => {
        let deferredPrompt;

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault(); // Prevent the mini-infobar from appearing
            deferredPrompt = e;

            if (!localStorage.getItem("appInstalled")) {
                setShowPopup(true); // Show the popup only if not installed
            }
        };

        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

        return () => window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    }, []);

    if (!isMobile || !showPopup) return null;

    return (
        <div className="app-popup">
            <div className="app-popup-content">
                <p>Get the best experience! Download our mobile app now</p>
                <button className="install-btn" onClick={handleInstall}>
                    Download
                </button>
                <button className="close-btn" onClick={handleClose}>
                    ✕
                </button>
            </div>
        </div>
    );
}

export default AppPopup;