
export const TOGGLEMAILNAV = "TOGGLEMAILNAV";
export const AREACHARGE = "AREACHARGE";

export const LOADING_LOGIN = "LOADING_LOGIN";
export const LOADING_SIGNUP = "LOADING_SIGNUP";
export const SET_USER = "SET_USER";
export const ERROR_MESSAGE = "ERRORMESSAGE";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const START_LOADING_APP = "START_LOADING_APP";
export const STOP_LOADING_APP = "STOP_LOADING_APP";

//APP ACTIONS
export const APP_LOADING = "APPLICATION-LOADING";
export const APP_SUCCESS = "APPLICATION-SUCCESS";
export const APP_ERR = "APPLICATION-ERROR";
export const APP_LIST = "APPLICATION-LIST";
export const CARTS_LIST = 'SHOP-LIST'
export const CART_ITEM = 'SHOP-CART_ITEM'
export const ADD_CART = 'SHOP-ADD_CART'
export const UPDATE_CART = 'SHOP-UPDATE-CART'
export const DELETE_CART = 'SHOP-DELETE_CART'
export const DELETE_CARTS = 'SHOP-DELETE_CARTs'
export const ORDER_ERROR = 'SHOP-ORDER_ERROR'
export const ORDER_RESP = 'SHOP-ORDER_RESP'
export const STOCK_LIST = 'SHOP-STOCK_LIST'

// Functions for cart number reduction aysha
export const DECREMENT_ITEM_QUANTITY = 'DECREMENT_ITEM_QUANTITY';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';




