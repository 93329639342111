import React, { useState } from 'react';
import styled from 'styled-components';
import { FaStar } from 'react-icons/fa';

// Styled Components
const Container = styled.div`
  display: flex;
  width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  border-radius: 8px;
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column; 
//   align-items: center;
`;

const ThumbnailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
//   background-color: #000;
  gap: 1px;
  justify-content: center;
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  border: ${({ isSelected }) => (isSelected ? '2px solid black' : 'none')};
`;

const MainImage = styled.img`
  width: 350px;
  height: 400px;
  margin: 10px;
`;

const ProductDetails = styled.div`
  flex: 2;
  padding-left: 20px;
  padding-top: 20px;
`;

const Title = styled.h2`
  font-size: 26px;
  font-weight: 500;
  color: #333;
`;

const PriceSection = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 10px;
`;

const Price = styled.div`
  font-size: 1.5em;
  color: #d32f2f;
  margin-right: 10px;
`;

const SizeOptions = styled.div`
  margin: 15px 0;
`;

const SizeButton = styled.button`
  background-color: ${({ isSelected }) => (isSelected ? '#ddd' : '#fff')};
  border: 1px solid #ddd;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px;
`;

const QuantitySection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20%;
  gap: 10px;
  
`;

const QuantityButton = styled.button`
  background-color: #eee;
  border: 1px solid #ccc;
  width: 30px;
  cursor: pointer;
  border-radius: 5px;
  // height: 20px;
  // padding: 0px
`;

const Quantity = styled.div`
  margin: 0 5px;
  font-size: 0.9em;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

// const BuyNowButton = styled.button`
//   background-color: #d32f2f;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   cursor: pointer;
//   font-size: 1em;
// `;

const AddToCartButton = styled.button`
  POSITION: absolute;
  background-color: #542b2b;
  color: white;
  border: none;
  padding: 10px 6px;
  cursor: pointer;
  font-size: 1em;
  width: 20%;
  top: 76%;
  border-radius: 5px;
`;

const ResponsiveSalesCount = styled.div`
  font-size: 12px;
  width: 100%;
  color: #542b2b;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 11px; /* Slightly smaller font on mobile */
  }
`;

const ResponsiveBagde = styled.span`
    margin-left: 8px;
    font-size: 12px;
    color: #542b2b;
    font-weight: bold;
`;

// Main Component
const CustomAddtoCard = () => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState('All yards');
  const [quantity, setQuantity] = useState(1);
  const [mainImage, setMainImage] = useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbA6bet2uy5azo2oC6GYP_yn3aSm2FUpONeQ&s"); // Default main image

  const colors = [
    { imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxkzn4hSb53THHRkdPdH_sQHYzJiAeUpLD2Q&s" },
    { imageUrl: "https://i.ebayimg.com/images/g/0bwAAOSwOs1irfoc/s-l1200.jpg" },
    { imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIwTh_A_8xaftJQGxhfa5WNLQoraxL5ThcxQ&s" },
    { imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbA6bet2uy5azo2oC6GYP_yn3aSm2FUpONeQ&s" }
  ];

  const sizes = ['All yards'];

  const handleThumbnailClick = (imageUrl, colorName) => {
    setMainImage(imageUrl);
    setSelectedColor(colorName);
  };

  return (
    <Container className="card-shadow shadow">
      <ImageSection>
        <MainImage src={mainImage} alt="Selected Style" />
        <ThumbnailsContainer>
          {colors.map((color, index) => (
            <Thumbnail
              key={index}
              src={color.imageUrl}
              isSelected={selectedColor === color.imageUrl}
              onClick={() => handleThumbnailClick(color.imageUrl, `Style ${index + 1}`)}
            />
          ))}
        </ThumbnailsContainer>
      </ImageSection>

      <ProductDetails>
        <Title>Men's Summer Socks Low-cut Absorbent Anti-odor Cotton Ankle Socks</Title>
        <ResponsiveSalesCount>
          {[0, 1, 2, 3, 4].map((rating) => (
            <FaStar
              size={13}
              key={rating}
              aria-hidden="true"
              color={3.5 > rating ? "brown" : "#e0e0e0"}
            />
          ))}
          <ResponsiveBagde>200+ sold</ResponsiveBagde>
        </ResponsiveSalesCount>
        <PriceSection>
          <Price>NGN2,776.42</Price>
        </PriceSection>
        <div>
          <strong>Color:</strong> {selectedColor || 'Select a style'}
        </div>
        <SizeOptions>
          <strong>Size:</strong>
          {sizes.map((size) => (
            <SizeButton key={size} isSelected={selectedSize === size} onClick={() => setSelectedSize(size)}>
              {size}
            </SizeButton>
          ))}
        </SizeOptions>

        <QuantitySection>
          <strong>Quantity:</strong>
          <QuantityButton onClick={() => setQuantity(Math.max(1, quantity - 1))}>-</QuantityButton>
          <Quantity>{quantity}</Quantity>
          <QuantityButton onClick={() => setQuantity(quantity + 1)}>+</QuantityButton>
        </QuantitySection>

        <ActionButtons>
          <AddToCartButton>Add to Cart</AddToCartButton>
          {/* <BuyNowButton>Buy Now</BuyNowButton> */}
        </ActionButtons>
      </ProductDetails>
    </Container>
  );
};

export default CustomAddtoCard;