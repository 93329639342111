import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./Shop/ShopIndex.css";
import "./App.css";
import AppNavigation from "./Route/AppNavigation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { init } from "./redux/action/auth";
import { ToastContainer } from "react-toastify";
import AppPopup from "./InstallPopup";

function App() {
  const authenticated = useSelector((state) => state.auth.authenticated);
  const loadingApp = useSelector((state) => state.auth.loadingApp);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authenticated) {
      dispatch(init());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    document.title = "KASUWA MALL";
  }, []);

  // if (loadingApp)
  //   return (
  //     <div>
  //       <h1>Loading...</h1>
  //     </div>
  //   );

  return (
    <div className="whole">
      <AppNavigation />
      {/* <ShopSidebar /> */}
      <ToastContainer />
      <AppPopup />
    </div>
  );
}

export default App;
