import React from "react";
import styled from "styled-components";

const SkeletonCardContainer = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0;
  animation: pulse 1.5s infinite ease-in-out;

  @keyframes pulse {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

const SkeletonImage = styled.div`
  width: 100%;
  height: 300px;
  background-color: #ddd;
`;

const SkeletonInfo = styled.div`
  padding: 16px;
`;

const SkeletonTitle = styled.div`
  height: 16px;
  background-color: #ccc;
  width: 70%;
  margin-bottom: 8px;
`;

const SkeletonDetails = styled.div`
  height: 14px;
  background-color: #ccc;
  width: 50%;
  margin-bottom: 8px;
`;

const SkeletonPrice = styled.div`
  height: 18px;
  background-color: #ccc;
  width: 30%;
`;

const SkeletonLoader = () => (
  <SkeletonCardContainer>
    <SkeletonImage />
    <SkeletonInfo>
      <SkeletonTitle />
      <SkeletonDetails />
      <SkeletonPrice />
    </SkeletonInfo>
  </SkeletonCardContainer>
);

export default SkeletonLoader;