import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import defaultImg from "../../Images/No-Image-Placeholder.jpg";
import { addCart, updateCart } from "../../redux/action/shop";
import { _get, separator } from "../../utils/Helper";
import { FaStar } from "react-icons/fa";
import { Button } from "reactstrap";

const ShopContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 16px;
`;

const CardContainer = styled.div`
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProductImage = styled.img`
  // max-width: 100%;
  // max-height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  padding: 16px;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 8px 0;
  text-transform: capitalize;
`;

const SalesCount = styled.div`
  font-size: 12px;
  color: #777;
  display: flex;
  align-items: center;
`;

const Bagde = styled.span`
  margin-left: 8px;
  font-weight: bold;
  color: #542b2b;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

const NairaSign = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-right: 4px;
`;

const NewDiscount = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #542b2b;
`;

const Footer = styled.div`
  // position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 8px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden; /* Hide the footer by default */
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease; /* Smooth transition */

  ${CardContainer}:hover & {
    visibility: visible; /* Show the footer when the card is hovered */
    opacity: 1;
  }

  @media (max-width: 768px) {
    visibility: visible; /* Show the footer on mobile */
    opacity: 1;
    position: static;
    margin-top: 0px;
  }
`;

const AddToCartButton = styled.button`
 background-color: transparent;
  color: #542b2b;
  border: 1px solid #542b2b;
  padding: 8px 16px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  transition: color 0.5s;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: #542b2b;
    height: 150px;
    width: 200px;
    border-radius: 50%;
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }
    &:hover:before {
      top: -30px;
  left: -30px;
    }
  &:hover {
    color: #fff;
  }
  }
`;

const ShopView = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { carts } = useSelector((state) => state.shop);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    _get(
      `api/get-products`,
      (resp) => {
        setProducts(resp.result[0]);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );
  }, []);

  const handleAddToCart = (item) => dispatch(addCart({ ...item, qty: 1 }));
  const handleProductClick = (product) =>
    navigate(`/category/${product.product_category}/${product.product_id}`);

  if (loading) return <div>Loading...</div>;

  const addCartItem = (item) =>
    dispatch(updateCart({ product_id: item.product_id, qty: item.qty + 1 }));
  const deleteCart = (item) =>
    dispatch(updateCart({ product_id: item.product_id, qty: item.qty - 1 }));

  const selected = carts.find((a) => a.product_id === products.product_id);
  if (error) return <div>Error loading products: {error.message}</div>;

  return (
    <div className="container" style={{ marginTop: "200px" }}>
      <div>
        <h1>Welcome to ; {products.name}</h1>
      </div>
      <ShopContainer>
        {products.map((product, index) => (
          <CardContainer key={index}>
            <div onClick={() => handleProductClick(product)}>
              <ImageContainer>
                <ProductImage
                  src={
                    product.image_urls
                      ? product.image_urls.split(",")[0]
                      : defaultImg
                  }
                  alt={product.name}
                />
              </ImageContainer>
              <InfoContainer>
                <Title>{product.name}</Title>
                <SalesCount>
                  {[...Array(5)].map((_, i) => (
                    <FaStar
                      key={i}
                      size={13}
                      color={product.product_rating > i ? "brown" : "#e0e0e0"}
                    />
                  ))}
                  <Bagde>200+ sold</Bagde>
                </SalesCount>
                <PriceContainer>
                  <NairaSign>₦</NairaSign>
                  <NewDiscount>{separator(product.price)}</NewDiscount>
                </PriceContainer>
              </InfoContainer>
            </div>
            <Footer>
              <div className="d-flex justify-content-center align-items-center">
                {selected ? (
                  <div className="d-flex">
                    <div className="d-flex text-center inc_dec_btn_div rounded">
                      <Button
                        className="inc_dec_btn"
                        onClick={() => addCartItem(selected)}
                      >
                        +
                      </Button>
                      <div
                        className="p-2 py-1 mx-2 text-light rounded"
                        style={{ background: "#542b2b" }}
                      >
                        {selected.qty}
                      </div>
                      <Button
                        className="inc_dec_btn"
                        onClick={() => deleteCart(selected)}
                      >
                        -
                      </Button>
                    </div>
                  </div>
                ) : (
                  <AddToCartButton onClick={() => handleAddToCart(product)}>
                    Add to Cart
                  </AddToCartButton>
                )}
              </div>
            </Footer>
          </CardContainer>
        ))}
      </ShopContainer>
    </div>
  );
};

export default ShopView;
