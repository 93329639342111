import React from "react";
import styled from "styled-components";
import { FaStar } from "react-icons/fa";
import { separator } from "../utils/Helper";
import defaultImg from "../Images/No-Image-Placeholder.jpg";
import { useNavigate } from "react-router";
import { Button } from "reactstrap";
import { Badge } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { addCart, deleteCart, updateCart } from "../redux/action/shop";
import "./shop.css";

const CardContainer = styled.div`
  width: 100%;
  // border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  padding: 10px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // height: 25rem;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 768px) {
    // height: 700px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 250px;
  // background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 150px;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  // border: 1px solid #e0e0e0;
`;

const InfoContainer = styled.div`
  padding: 16px;
  // background-color: #fff;
  @media (max-width: 768px) {
    padding: 10px 0 0 0;
  }
`;

const Title = styled.h2`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: ellipsis;
`;

const Details = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
  color: #777;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const NairaSign = styled.span`
  font-size: 15px;
  margin-right: 4px;
  font-weight: bold;
`;

const NewDiscount = styled.span`
  font-size: 18px;
  color: #542b2b;
  font-weight: bold;
`;

const Footer = styled.div`
  // position: absolute;
  bottom: 0;
  width: 100%;
  // background-color: #fff;
  padding: 8px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden; /* Hide the footer by default */
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease; /* Smooth transition */

  ${CardContainer}:hover & {
    visibility: visible; /* Show the footer when the card is hovered */
    opacity: 1;
  }

  @media (max-width: 768px) {
    visibility: visible; /* Show the footer on mobile */
    opacity: 1;
    position: static;
    margin-top: 0px;
  }
`;

const AddToCartButton = styled.button`
  background-color: transparent;
  color: #542b2b;
  border: 1px solid #542b2b;
  padding: 8px 16px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  transition: color 0.5s;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: #542b2b;
    height: 150px;
    width: 200px;
    border-radius: 50%;
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }
    &:hover:before {
      top: -30px;
  left: -30px;
    }
  &:hover {
    color: #fff;
  }
  }
`;
const SalesCount = styled.div`
  font-size: 12px;
  color: #542b2b;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  display: flex;

  @media (max-width: 768px) {
    font-size: 11px; /* Slightly smaller font on mobile */
  }
`;
const Bagde = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: #542b2b;
  font-weight: bold;
`;

const ProductCard = ({
  product,
  openQty,
  quantity,
  handleAddToCart,
  handleQuantityChange,
  deleteCartItem,
  // addCartItem,
}) => {
  const { carts } = useSelector((state) => state.shop);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleItemClick = () => {
    navigate(`/category/${product.product_category}/${product.product_id}`);
  };

  const addToCart = (item) => dispatch(addCart({ ...item, qty: 1 }));
  const addCartItem = (item) =>
    dispatch(updateCart({ product_id: item.product_id, qty: item.qty + 1 }));
  const deleteCart = (item) =>
    dispatch(updateCart({ product_id: item.product_id, qty: item.qty - 1 }));

  const selected = carts.find((a) => a.product_id === product.product_id);

  return (
    <CardContainer>
      <div onClick={handleItemClick}>
        <ImageContainer>
          <ProductImage
            src={
              product.image_urls ? product.image_urls.split(",")[0] : defaultImg
            }
            alt={product.name}
          />
        </ImageContainer>
        <InfoContainer>
          <Title>{product.name}</Title>
          {/* <Details>{product.description}</Details> */}
          <SalesCount>
            {[0, 1, 2, 3, 4].map((rating) => (
              <FaStar
                size={13}
                key={rating}
                aria-hidden="true"
                color={product.product_rating > rating ? "brown" : "#e0e0e0"}
              />
            ))}
            <Bagde>200+ sold</Bagde>
          </SalesCount>
          <PriceContainer>
            <NairaSign>₦</NairaSign>
            <NewDiscount>{separator(product.price)}</NewDiscount>
          </PriceContainer>
        </InfoContainer>
      </div>
      <Footer>
        <div className="d-flex justify-content-center align-items-center">
          {openQty ? (
            <div className="d-flex">
              <div className="d-flex text-center inc_dec_btn_div rounded">
                <Button
                  className="inc_dec_btn"
                  onClick={() => deleteCart(selected)}
                  disabled={selected.qty === 1}
                >
                  -
                </Button>
                <div
                  className="p-2 py-1 mx-2 text-light rounded"
                  style={{ background: "#542b2b" }}
                >
                  {selected.qty}
                </div>
                <Button
                  className="inc_dec_btn"
                  onClick={() => addCartItem(selected)}
                >
                  +
                </Button>
              </div>
            </div>
          ) : (
            <AddToCartButton onClick={() => handleAddToCart(product)}>
              Add to Cart
            </AddToCartButton>
          )}
        </div>
      </Footer>
    </CardContainer>
  );
};

export default ProductCard;
