import { React, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCar,
  faPlane,
  faShop,
  faShoppingBag,
  faTv,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper/modules";
import "./shophomepage.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import item1 from "../../Images/item1.webp";
import item2 from "../../Images/item2.webp";
import item3 from "../../Images/item3.webp";
import item4 from "../../Images/item4.webp";
import item5 from "../../Images/item5.webp";
import carousel1 from "../../Images/photo1.jpg";
import carousel2 from "../../Images/2.jpg";
import carousel3 from "../../Images/photo2.jpg";
import carousel4 from "../../Images/3.jpg";
import carousel5 from "../../Images/photo3.jpg";
import carousel6 from "../../Images/photo4.jpg";
import subcarousel1 from "../../Images/subcrousel1.webp";
import subcarousel2 from "../../Images/subcarousel2.webp";
import subcarousel3 from "../../Images/subcarousel3.jpg";
import subcarousel4 from "../../Images/subcarousel4.webp";
import Image from "../../Images/images.jpeg";
import phone from "../../Images/phone.webp";
import appliances from "../../Images/appliances.png";
import baby from "../../Images/baby.webp";
import beauty from "../../Images/beauty.png";
import shoes from "../../Images/shoe.png";
import gaming from "../../Images/gaming.webp";
import health from "../../Images/health.webp";
import home from "../../Images/home.webp";
import electronics from "../../Images/speaker.webp";
import sport from "../../Images/sport.webp";
import drinks from "../../Images/drinks.webp";
import fabric from "../../Images/fabrics.png";
import { Home, PhoneCall, Store, Trash } from "lucide-react";
import { _get } from "../../utils/Helper";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const SkeletonCard = styled.div`
  width: 100%;
  height: 280px;
  background-color: #f0f0f0;
  border-radius: 8px;
  animation: ${shimmer} 1.5s infinite linear;
`;

const SkeletonImage = styled.div`
  width: 100%;
  height: 200px;
  background: linear-gradient(to right, #ddd 0%, #f0f0f0 50%, #ddd 100%);
  background-size: 200% 100%;
  margin-bottom: 16px;
`;

const SkeletonText = styled.div`
  background-color: #ddd;
  height: 20px;
  width: 80%;
  margin: 0 auto;
  animation: ${shimmer} 1.5s infinite linear;
  margin-bottom: 10px;
`;

const CategoryCardSkeleton = () => (
  <Col md={2} className="p-1">
    <Card className="border-0 card-hover show category_card_item">
      <SkeletonImage />
      <CardBody className="p-1">
        <SkeletonText />
      </CardBody>
    </Card>
  </Col>
);

export default function ShopHomepage() {
  const handleClick = () => {
    const newWindow = window.open(`product`);
    if (newWindow) {
      newWindow.focus();
    }
  };
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const slides = [
    { id: 1, img: carousel1 },
    { id: 2, img: carousel2 },
    { id: 3, img: carousel3 },
    { id: 4, img: carousel4 },
    { id: 5, img: carousel5 },
    { id: 6, img: carousel6 },
  ];

  const subSlides = [
    { id: 1, img: subcarousel1 },
    { id: 2, img: subcarousel2 },
    // { id: 3, img: subcarousel3 },
    // { id: 4, img: subcarousel4 },
  ];

  const anotherslide = [
    {
      id: 2,
      info: (
        <div>
          <span className="d-flex mb-3" style={{ gap: "15px" }}>
            <div
              className="d-flex justify-content-center align-items-center p-2"
              style={{
                border: "2px solid #542b2b",
                borderRadius: "100px",
                width: "50px",
                height: "50px",
                color: "#542b2b",
              }}
            >
              <PhoneCall />
            </div>
            <div>
              <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                CALL TO ORDER
              </p>
              <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                07017222999 , 07030975118
              </p>
            </div>
          </span>
          <Link
            to={"https://app.kasuwamall.com/"}
            className="text-dark"
            style={{ textDecoration: "none" }}
          >
            <span className="d-flex mb-3" style={{ gap: "15px" }}>
              <div
                className="d-flex justify-content-center align-items-center p-2"
                style={{
                  border: "2px solid #542b2b",
                  borderRadius: "100px",
                  width: "50px",
                  height: "50px",
                  color: "#542b2b",
                }}
              >
                <Home />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                  SELL IN KASUWAMALL
                </p>
              </div>
            </span>
          </Link>
          <span className="d-flex mb-3" style={{ gap: "15px" }}>
            <div
              className="d-flex justify-content-center align-items-center p-2"
              style={{
                border: "2px solid #542b2b",
                borderRadius: "100px",
                width: "50px",
                height: "50px",
                color: "#542b2b",
              }}
            >
              <Trash />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                Best Deals
              </p>
            </div>
          </span>
          <span
            className="d-flex "
            onClick={() => navigate("/purchase-by-shop")}
            style={{ gap: "15px", cursor: "pointer" }}
          >
            <div
              className="d-flex justify-content-center align-items-center p-2"
              style={{
                border: "2px solid #542b2b",
                borderRadius: "100px",
                width: "50px",
                height: "50px",
                color: "#542b2b",
              }}
            >
              <Store />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                Purshase by Shop
              </p>
            </div>
          </span>
        </div>
      ),
    },
  ];

  const category = [
    { id: 1, image: appliances, title: "Appliances", name: "appliances" },
    { id: 2, image: phone, title: "Phones & Tablets", name: "gadgets" },
    { id: 3, image: beauty, title: "Beauty", name: "beauty" },
    { id: 4, image: electronics, title: "Electronics", name: "electronics" },
    { id: 5, image: shoes, title: "Fashion", name: "fashion" },
    { id: 6, image: baby, title: "Baby Products", name: "baby-Product" },
    { id: 7, image: gaming, title: "Gaming", name: "gaming" },
    { id: 8, image: drinks, title: "Drinks", name: "drinks" },
    { id: 9, image: home, title: "Home & Office", name: "home&office" },
    { id: 10, image: health, title: "Health", name: "health" },
    { id: 11, image: fabric, title: "Fabrics", name: "fabric" },
    { id: 12, image: sport, title: "Sporting Goods", name: "sporting" },
  ];

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  const getCategories = () => {
    _get(
      "api/categories",
      (resp) => {
        setCategories(resp.results[0]);
        setLoading(false); // Set loading to false once categories are fetched
      },
      (err) => {
        setError(err);
        setLoading(false); // Set loading to false if an error occurs
      }
    );
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      {/* This is the jumbotron part of the code where the slider and sub component */}
      <div className="pt-xl-5 container-mod container-fluid">
        <div className="card d-flex justify-content-center align-items-center d-xl-none mb-3 mt-4 ">
          <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
            CALL TO ORDER
          </p>
          <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
            07017222999 , 07030975118
          </p>
        </div>
        <div className="row mb-4 hero-div">
          <Col lg={2} md={6} className="d-lg-block" style={{ display: "none" }}>
            <div className="row">
              {subSlides.map((subSlide) => (
                <Col md={12} className="sub-carousel p-1" key={subSlide.id}>
                  <div className="sub-slide-background rounded w-100 h-100">
                    <img
                      src={subSlide.img}
                      alt="slide"
                      className="w-100 h-100 rounded"
                    />
                  </div>
                </Col>
              ))}
            </div>
          </Col>
          <Col lg={7} md={8} className="mySwiper-col">
            {/* swipper part */}
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              effect={"fade"}
              // navigation={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Navigation, Autoplay, EffectFade]}
              className="mySwiper rounded h-100"
            >
              {slides.map((slide) => (
                <SwiperSlide
                  className="slide-background h-100 w-100"
                  key={slide.id}
                >
                  <img
                    src={slide.img}
                    alt="slide"
                    className="w-100 h-100"
                    style={{ objectFit: "fill" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>

          {/* components part */}
          <Col lg={3} md={3} className="d-lg-block" style={{ display: "none" }}>
            <Card className="row p-3 call_card">
              {anotherslide.map((anotherslide) => (
                <Col md={12} className="sub-carousel p-1" key={anotherslide.id}>
                  <div className="">{anotherslide.info}</div>
                </Col>
              ))}
            </Card>
          </Col>
        </div>
        <div className="category_card_diverder">
          <Card className="mt-3 mb-5 shop-main-card p-4 category_card">
            <p
              style={{
                fontWeight: "bold",
                padding: "0",
                marginBottom: "12px",
                fontSize: 20,
              }}
            >
              Top Selling Categories{" "}
            </p>
            <Row className="card-img-hide-col">
              {loading
                ? [...Array(6)].map((_, idx) => (
                    <CategoryCardSkeleton key={idx} />
                  )) // Show skeleton loaders
                : categories.slice(0, 6).map((ctgr) => (
                    <Col md={2} className="p-1 " key={ctgr.id}>
                      <Card
                        className="border card-hover show category_card_item"
                        onClick={() => handleCategoryClick(ctgr.ctgry_id)}
                      >
                        <CardImg
                          variant="top"
                          src={ctgr.ctgry_image_urls}
                          className="card-img-hide w-100"
                        />
                        <CardBody className="p-1">
                          <CardTitle className="text-center category-titile">
                            {ctgr.ctgry_name}
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
            </Row>
          </Card>
        </div>

        <div className="industry-list">
          <div className="slider-arrow-wrapper prev"></div>
          <div className=""></div>
          <div className="slider-arrow-wrapper prev"></div>
        </div>
      </div>
    </>
  );
}
