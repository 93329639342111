import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, FormGroup, Row, Spinner, Button } from "reactstrap";
import Swal from "sweetalert2";
import { _post } from "../utils/Helper";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

export default function SignUp({ setType, toggle }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    username: "",
    address: "",
    status: "approved",
    role: "user",
    accepted_terms: false,
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

  const handleChange = ({ target: { name, value, type, checked } }) => {
    setForm((s) => ({ ...s, [name]: type === "checked" ? checked : value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation check
    if (!form.firstname || !form.lastname || !form.email || !form.password) {
      Swal.fire({
        icon: "warning",
        title: "Missing Fields",
        text: "All fields are required to proceed!",
      });
      return;
    }

    setLoading(true);

    _post(
      "api/users/create",
      form,
      (res) => {
        console.log(res);
        if (res.success) {
          Swal.fire({
            icon: "success",
            title: "Account Created",
            text: "Your account has been created successfully!",
            confirmButtonText: "OK",
          }).then(() => setType("Login"));
          setLoading(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error Message",
            text: res.message,
          });
          setLoading(false);
        }
      },
      (err) => {
        const errorMessage =
          err.response?.data?.message || "An error occurred while creating your account.";
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
        setLoading(false);
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <div className="singIn2">
          <div>
            <h1 className="SingUp-first-header">Create New Account</h1>
            <div className="input-div">
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="text"
                  placeholder="First Name"
                  name="firstname"
                  value={form.firstname}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="text"
                  placeholder="Last Name"
                  name="lastname"
                  value={form.lastname}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="text"
                  placeholder="Username"
                  name="username"
                  value={form.username}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Phone Number (+234)"
                  className="SingUp-first-input"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="Email"
                  placeholder="Email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup style={{ position: "relative" }}>
                <Input
                  className="SingUp-first-input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                />
                <Button
                  type="button"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Show icon based on state */}
                </Button>
              </FormGroup>
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="textarea"
                  name="address"
                  value={form.address}
                  placeholder="Address"
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <label
                style={{
                  marginLeft: 15,
                  marginBottom: 10,
                }}
              >
                <Input
                  style={{
                    cursor: "pointer",
                    marginLeft: 4,
                  }}
                  type="checkbox"
                  name="accepted_terms"
                  checked={form.accepted_terms}
                  value={form.accepted_terms}
                  onChange={handleChange}
                />
                <span
                  style={{
                    marginLeft: 10,
                  }}
                  className="cursor"
                >
                  I accept the
                </span>
                <a
                  href=""
                  className="href"
                  style={{
                    marginLeft: 4,
                  }}
                >
                  Terms & Conditions
                </a>
                and Privacy and Cookie Notice.
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button onClick={handleSubmit} className="btn-for-signUp2" disabled={loading}>
                {loading ? <Spinner /> : <>Sign Up</>}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
                justifyContent: "center",
              }}
            >
              <p>Already have an Account ?</p>
              <a
                href="/#"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setType("Login");
                }}
                style={{ marginLeft: 15 }}
                className="link-for-login-signup"
              >
                LogIn
              </a>
            </div>
          </div>
        </div>
      </Row>
    </Form>
  );
}