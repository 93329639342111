import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, Col, Row, Spinner } from "reactstrap";
import {
  addCart,
  deleteCart,
  getStockList,
  updateCart,
} from "../redux/action/shop";
import { _get } from "../utils/Helper";
import Splide from "./Splide";
import image1 from "../Images/shoe.png";
import image2 from "../Images/bag.png";
import ItemDetails from "./ItemDetails";
import image3 from "../Images/shoe.png";
import "./ShopItems.css";
import ProductDetails from "./ProductDetails";
import ShopHomepage from "./shopHomepage/ShopHomepage";
import lg from "../Images/brands/lg.webp";
import hp from "../Images/brands/hp.webp";
import apple from "../Images/brands/apple.webp";
import cera from "../Images/brands/cera.webp";
import haier from "../Images/brands/haier.webp";
import infinix from "../Images/brands/infinix.webp";
import intel from "../Images/brands/intel.webp";
import itec from "../Images/brands/itec.webp";
import lenovo from "../Images/brands/lenovo.webp";
import ring from "../Images/brands/ring.webp";
import samsung from "../Images/brands/samsung.webp";
import starlink from "../Images/brands/starlink.webp";
import tgi from "../Images/brands/tgi.webp";
import tecno from "../Images/brands/tecno.webp";
import unilever from "../Images/brands/unilever.webp";
import vivo from "../Images/brands/vivo.webp";

import advert from "../Images/advert.webp";
import ProductDet from "./ProductDet";
import { Mainproduct } from "./Mainproduct";
import ProductCard from "./ProductCard";
import ProductCardData from "./SecondPRoductCard";
import SkeletonLoader from "./SkeletonCardContainer";

// method to open new tab

const ShopItems = () => {
  const navigate = useNavigate();
  const { carts, stocks } = useSelector((s) => s.shop);
  const [loading, setLoading] = useState(true);
  const [loadingNew, setLoadingNew] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState({});
  const [openQty, setOpenQty] = useState({});

  const getList = useCallback(() => {
    if (stocks.length < 1) {
      dispatch(getStockList(setLoading));
    }
  }, [stocks.length, dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  // const officialStore = [
  //   { id: 1, image: lg },
  //   { id: 2, image: hp },
  //   { id: 3, image: tecno },
  //   { id: 4, image: lenovo },
  //   { id: 5, image: tgi },
  //   { id: 6, image: haier },
  //   { id: 7, image: vivo },
  //   { id: 8, image: unilever },
  //   { id: 9, image: apple },
  //   { id: 10, image: cera },
  //   { id: 11, image: infinix },
  //   { id: 12, image: intel },
  //   { id: 13, image: itec },
  //   { id: 14, image: samsung },
  //   { id: 15, image: ring },
  //   { id: 16, image: starlink },
  // ];

  const [sliderItems, setSliderItems] = useState([]);

  useEffect(() => {
    setLoadingNew(true);
    setError(null);

    _get(
      `api/getallproducts`,
      (res) => {
        setSliderItems(res.result.slice(0, 20));
        setLoadingNew(false);
      },
      (err) => {
        setError(err);
        setLoadingNew(false);
      }
    );
  }, []);

  const newarrival = sliderItems?.slice(-20);

  const handleAddToCart = (product) => {
    const selectedQuantity = quantity[product.product_id] || 1;
    console.log("Adding product to cart with quantity:", selectedQuantity);

    const selectedImage =
      product.image_urls && product.image_urls.includes(",")
        ? product.image_urls.split(",")[0]
        : product.image_urls;

    dispatch(
      addCart({
        ...product,
        qty: selectedQuantity,
        selectedImage: selectedImage || "default-placeholder.png",
      })
    );

    setOpenQty((prevState) => {
      const updatedState = { ...prevState };

      Object.keys(updatedState).forEach((key) => {
        updatedState[key] = true;
      });

      updatedState[product.product_id] = true;

      return updatedState;
    });
  };

  const handleQuantityChange = (productId, amount) => {
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [productId]: Math.max(1, (prevQuantity[productId] || 1) + amount),
    }));
  };

  //  const { carts } = useSelector((state) => state.shop);
  //  const dispatch = useDispatch();

  const deleteCartItem = (item) => {
    if (item.qty <= 1) {
      dispatch(deleteCart(item.item_code));
    } else {
      dispatch(updateCart({ item_code: item.item_code, qty: item.qty - 1 }));
    }
  };
  const addCartItem = (item) => {
    dispatch(updateCart({ item_code: item.item_code, qty: item.qty + 1 }));
  };

  return (
    <>
      <ShopHomepage />
      <Card className="mt-1 shop-main-card shadow px-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="shop-card-title text-start mb-2 mt-0">Today Deals </p>
          <p className="mb-2 pe-2 see-all">See all {">"}</p>
        </div>
        {/* {JSON.stringify(newarrival)} */}
        <Row>
          <Splide content={newarrival} loadingNew={loadingNew} href={"#"} />
        </Row>
      </Card>

      {/* <Card className="px-0 border-0 mt-4">
        <CardHeader
          className="official-store w-100 py-1 d-flex align-items-center justify-content-center card-header-no-radius"
          style={{ background: "#36454F", color: "white" }}
        >
          <span className="fw-bold" style={{ fontSize: "18px" }}>
            Official Store
          </span>
        </CardHeader>
        <CardBody className="p-0">
          <Row className="mx-0">
            {officialStore?.map((brand) => (
              <Col md={2} sm={4} key={brand.id} className="p-2">
                <img src={brand.image} className="rounded w-100 h-auto" />
              </Col>
            ))}
          </Row>
        </CardBody>
        </Card> */}
      <Card className="mt-5 shop-main-card">
        <div style={{ width: "100%", alignItems: "center", textAlign: 'center' }}>
          <p className="product_slider_title pb-4">EXPLORE YOUR INTEREST</p>
        </div>
        {/* {JSON.stringify()} */}
        <div className="product_slider_new_data">
          {loadingNew
            ? Array.from({ length: 6 }).map((_, index) => (
              <SkeletonLoader key={index} />
            ))
            : sliderItems.map((item) => (
              <ProductCard
                key={item.product_id}
                product={item}
                openQty={openQty[item.product_id] || false}
                quantity={quantity[item.product_id] || 1}
                handleAddToCart={() => handleAddToCart(item)}
                handleQuantityChange={(amount) =>
                  handleQuantityChange(item.product_id, amount)
                }
                deleteCartItem={deleteCartItem}
                addCartItem={addCartItem}
              />
            ))}
        </div>
      </Card>
    </>
  );
};
export default ShopItems;
