import React, { useState, useContext, useEffect } from "react";
import {
  ChevronDown,
  HelpCircle,
  Search,
  ShoppingCart,
  User,
} from "react-feather";
// import { Package2 } from "lucide-react";
import { CiSearch } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi";
import { LuUserCircle2 } from "react-icons/lu";
import { MdOutlineShoppingCart } from "react-icons/md";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  NavItem,
  Row,
} from "reactstrap";
import ProductDetails from "./ProductDetails";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/action/auth";
import AuthModal from "../Auths/AuthModal";
import Brand from "../Images/download.png";
import Brand2 from "../Images/download 2.png";
import "./ShopIndex.css";
import "./shop.css";
import { Link } from "react-router-dom";
import { _get, globalColor, useWindowWidth } from "../utils/Helper";
import KasuwaLogo from "../Images/KASUWAMALL cut.png";
import { FaUser, FaUserCheck } from "react-icons/fa";
import ShopSidebar from "./ShopSidebar";

export default function ShopNavbar({ onSearch }) {
  const {
    shop: { carts },
    auth: { user, authenticated },
  } = useSelector((s) => s);
  const [auth_type, setAuthType] = useState("Login");
  const [currentSize, setCurrentSize] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => {
    setOpen1(!open1);
  };
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const width = useWindowWidth();
  // useEffect (() => {
  //   if (width<768)
  // },[])

  // Define the dropdown state for each category
  const [dropdownOpen, setDropdownOpen] = useState(null);
  // const cart = () => {
  //   let data = localStorage.getItem("cart");
  //   return data;
  // };

  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const handleSearchSubmit = (e) => {
    navigate(`/search?query=${search}`);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    // if (storedUser) {
    //   // If user data is found in localStorage, set it to the state
    //   setIsLoggedIn(true);
    // }
    setIsLoggedIn(!!storedUser);
  }, []);

  const handleLoginSuccess = () => {
    toggleModal();
    toggle();
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("@@bits_lis");
    dispatch(logout());
    navigate("/");
    setIsLoggedIn(false);
  };

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [dbcategories, setCategories] = useState([]);
  const [Subcategories, setSubCategories] = useState([]);
  const [error, setError] = useState("");

  // Fetch categories on component mount
  const getCategories = () => {
    _get(
      "api/categories",
      (resp) => {
        setCategories(resp.results[0]);
      },
      (err) => {
        setError(err);
      }
    );
  };

  useEffect(() => {
    getCategories();
  }, []);

  // Fetch subcategories based on hovered category ID
  const getSubCategories = (ctgry_id) => {
    _get(
      `api/categories/types?category=${hoveredCategory}`,
      (resp) => {
        // alert(ctgry_id);
        setSubCategories(resp.results[0]);
      },
      (err) => {
        setError(err);
      }
    );
  };

  useEffect(() => {
    getSubCategories();
  }, [hoveredCategory]);

  const categories = [
    {
      id: 1,
      title: "All Categories",
      items: ["Computers and Accessories", "Phones and Tablets", "Electronics"],
    },
    {
      id: 2,
      title: "Fabrics",
      name: "fabric",
      items: ["Lace", "sharda", "Gizna"],
    },

    {
      id: 3,
      title: "Phones and Tablets",
      name: "gadgets",
      items: ["Smartphones", "Tablets", "Accessories"],
    },
    {
      id: 4,
      title: "Electronics",
      name: "electronics",
      items: ["TVs", "Home Audio", "Cameras"],
    },
    {
      id: 6,
      title: "Home and Office",
      name: "home&office",
      items: ["Furniture", "Appliances", "Cookware"],
    },
    {
      id: 7,
      title: "Baby, Kids and Toys",
      name: "baby-Product",
      items: ["Toys", "Kids' Furniture", "Baby Gear"],
    },
    {
      id: 8,
      title: "Computers and Accessories",
      name: "gadgets",
      items: ["Laptops", "Desktops", "Accessories"],
    },
  ];

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  const handleSearchclick = () => {
    navigate("/search/:search");
  };

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // const [myuser, setMyUser] = useState(null);

  //  useEffect(() => {
  //    // Check if there's a token and user data in localStorage
  //    const token = localStorage.getItem("authToken");
  //    const userData = localStorage.getItem("user");

  //    if (token && userData) {
  //      // Set user data from localStorage
  //      setMyUser(JSON.parse(userData));
  //    } else {
  //      // If no token, navigate to login page
  //      navigate("/login");
  //    }
  //  }, []);

  // const handleLoginSuccess = () => {
  //   setIsLoggedIn(true);
  //   toggle();
  // };

  const userDetails = useSelector((e) => e.userDetails);

  return (
    <div className="m-0 p-0" style={{ width: "80%" }}>
      {width < 768 ? (
        <Row
          className="px-3 small-size-navbar m-0 p-0"
          style={{ backgroundColor: globalColor.color3 }}
        >
          <Col
            md={12}
            className="d-flex align-items-center justify-content-between py-2 pb-2 px-2"
          >
            <div className="d-flex align-items-center justify-content-center">
              <GiHamburgerMenu
                style={{ fontSize: "28px", color: globalColor.color2 }}
                className="me-3"
                onClick={toggleSidebar}
              />
              <Link
                className="logo-text text-decoration-none"
                style={{
                  color: globalColor.color2,
                  fontWeight: "bold",
                  width: "80%",
                  height: "40px",
                }}
                to={"/"}
              >
                {/* {} */}
                <img
                  src={KasuwaLogo}
                  alt="kasuwamall logo"
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: 0,
                    margin: 0,
                  }}
                />
              </Link>
            </div>
            <div className="d-flex align-items-center">
              {authenticated ? (
                <FaUserCheck
                  // onClick={() => toggleModal()}
                  style={{
                    fontSize: "28px",
                    color: globalColor.color2,
                    border: "2px solid white",
                    borderRadius: "50px",
                    padding: "5px",
                  }}
                  className="me-4"
                />
              ) : (
                <FaUser
                  onClick={() => toggleModal()}
                  style={{
                    fontSize: "28px",
                    color: globalColor.color2,
                    border: "1px solid white",
                    borderRadius: "50px",
                    padding: "5px",
                  }}
                  className="me-4"
                />
              )}

              <div
                className="position-relative"
                onClick={() => navigate("/cart")}
              >
                <MdOutlineShoppingCart
                  style={{ fontSize: "28px", color: globalColor.color2 }}
                  className="w-100"
                />
                <Badge
                  color="secondary"
                  className="rounded-circle cart-badge position-absolute"
                  style={{ color: globalColor.color2 }}
                >
                  {carts.length > 0
                    ? carts.reduce((p, c) => p + parseInt(c.qty), 0)
                    : 0}
                </Badge>
              </div>
            </div>
          </Col>
          <Col md={12} className="pb-3">
            <div className="position-relative d-flex bg-light rounded-pill">
              <Input
                className="small-screen-search-input"
                placeholder="Search item here"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (e.target.value.trim() === "") {
                    navigate("/");
                  } else {
                    handleSearchSubmit();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchSubmit();
                  }
                }}
              />
              <div className="small-screen-search-div">
                <CiSearch
                  size={24}
                  onClick={handleSearchSubmit}
                  className="small-screen-search"
                  style={{}}
                />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="nav-row">
          {/* <Col md={2} className="d-flex ">
            <Link
              className=""
              style={{ fontWeight: "800", color: "white" }}
              to={"/"}
            >
              KASUWA MALL
            </Link>
          </Col> */}
          {/* {JSON.stringify(userDetails)}hjhjhjhjh */}
          <Col md={2} className="col1 d-flex ">
            <Link
              className="logo-text"
              style={{
                color: globalColor.color3,
                height: "60px",
                width: "50%",
                padding: 0,
                margin: 0,
              }}
              to={"/"}
            >
              <img
                src={KasuwaLogo}
                alt="kasuwamall logo"
                style={{
                  width: "100%",
                  height: "100%",
                  padding: 0,
                  margin: 0,
                }}
              />
            </Link>
          </Col>
          <Col md={6} className="col2 text-center d-flex align-items-center">
            <div className="search w-100 justify-content-center">
              <div className="search-container">
                {/* <form onSubmit={handleSearchSubmit}> */}
                <input
                  type="text"
                  placeholder="Search item here"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value); // Update search state
                    if (e.target.value.trim() === "") {
                      navigate("/"); // Redirect to homepage when input is empty
                    } else {
                      handleSearchSubmit(); // Trigger search as the user types
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearchSubmit();
                    }
                  }}
                />
                <button
                  onClick={handleSearchSubmit}
                  className="rounded-end search-icon-button"
                  style={{}}
                >
                  <CiSearch
                    size={20}
                    className="search-icon-button"
                    style={{}}
                  />
                </button>
                {/* </form> */}
              </div>
            </div>
          </Col>
          {/* {JSON.stringify(search)} */}
          <Col md={4} className="col3">
            <nav className="h-100">
              <ul className="nav-list mt-0 py-md-3 h-100">
                <li className="nav-item medium-search-button">
                  <button
                    type="submit"
                    className="rounded border-0 py-1 px-2 search-icon-button"
                  >
                    <CiSearch size={20} className="search-icon-button" />
                  </button>
                </li>
                <li className="nav-item d-flex align-items-center">
                  {/* {authenticated ? (
                    <Dropdown
                      className="dropdown"
                      isOpen={open}
                      toggle={toggle}
                    >
                      <DropdownToggle
                        className="dropdown-toggle"
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          color: "rgb(99, 99, 99)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <User size="1.5em" /> {user.username || user.email}{" "}
                        <ChevronDown />
                      </DropdownToggle>
                      < DropdownMenu className="dropdown-menu">
                        <DropdownItem
                          className="dropdown-item"
                          onClick={() => navigate("/account")}
                        >
                          Account
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => navigate("/account/orders")}
                          className="dropdown-item"
                        >
                          Orders
                        </DropdownItem>
                        <DropdownItem className="dropdown-item" divider />
                        <DropdownItem
                          onClick={() => dispatch(logout())}
                          className="dropdown-item"
                        >
                          Sign Out
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <NavItem className="">
                      <Button
                        onClick={() => toggleModal()}
                        className="btn btn-for-signUp3 mb-0"
                      >
                        Login
                      </Button>
                    </NavItem>
                  )} */}
                  {authenticated ? (
                    <Dropdown isOpen={open} toggle={toggle}>
                      <DropdownToggle
                        className="dropdown-toggle"
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          color: "rgb(99, 99, 99)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <User size="1.5em" style={{ marginRight: "8px" }} />
                        {user.username || user.email} {/* <ChevronDown /> */}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => navigate("/account")}>
                          Account
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => navigate("/account/orders")}
                        >
                          Orders
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={handleLogout}>
                          Sign Out
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <NavItem className="">
                      <Button
                        onClick={() => toggleModal()}
                        className="btn btn-for-signUp3 mb-0"
                      >
                        Login
                      </Button>
                    </NavItem>
                  )}
                </li>
                <li className="nav-item help">
                  <Dropdown
                    className="dropdown"
                    isOpen={open1}
                    toggle={toggle1}
                  >
                    <DropdownToggle
                      className="dropdown-toggle"
                      style={{
                        backgroundColor: "",
                        border: "none",
                        color: "rgb(99, 99, 99)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <HelpCircle size="1.5em" className="me-1" /> Help
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu">
                      <DropdownItem className="dropdown-item">
                        Settings
                      </DropdownItem>
                      <DropdownItem className="dropdown-item">
                        Help & Support
                      </DropdownItem>
                      <DropdownItem className="dropdown-item" divider />
                      <DropdownItem className="dropdown-item">
                        About us
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li
                  className="nav-item cart-icon"
                  onClick={() => navigate("/cart")}
                >
                  <ShoppingCart size="1.5em" /> Cart
                  <span className="cart-count">
                    {carts.length > 0
                      ? carts.reduce((p, c) => p + parseInt(c.qty), 0)
                      : 0}
                  </span>
                </li>
              </ul>
            </nav>
          </Col>
          <section className="mx-0 px-0 category-navbar-container">
            <div
              className="d-flex justify-content-center scrollable-row category-div"
              style={{}}
            >
              <div
                className="px-lg-4 px-md-3 py-2 text-white category-button"
                style={{ fontSize: "18px" }}
              >
                All Categories
              </div>
              {/* {JSON.stringify(Subcategories)} */}
              {dbcategories.slice(-8).map((category) => (
                <div
                  className="px-lg-4 px-md-3 py-2 text-white category-button"
                  style={{ fontSize: "18px" }}
                  key={category.id}
                  onMouseEnter={() => {
                    console.log(category.ctgry_id);
                    setHoveredCategory(category.ctgry_id);
                    getSubCategories(hoveredCategory);
                  }}
                  onMouseLeave={() => setHoveredCategory(null)}
                >
                  <span
                    className="category-button-title"
                    onClick={() => handleCategoryClick(category.ctgry_name)}
                  >
                    {category.ctgry_name}
                  </span>

                  {category.ctgry_id === hoveredCategory && (
                    <div className="category-menu">
                      <Row className="row mx-0">
                        {Subcategories.map((item, index) => (
                          <Col lg={3} md={4} key={index}>
                            <ul className="sub_ctgr_ul">
                              <li>{item.sub_ctgry_name}</li>
                              <p>Product under it</p>
                            </ul>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </div>
              ))}
              <div
                className="px-lg-4 px-md-3 py-2 text-white category-button"
                style={{ fontSize: "18px" }}
              >
                More
              </div>
            </div>
          </section>
        </Row>
      )}

      {sidebarVisible && (
        <div
          className={`sidebar-overlay ${sidebarVisible ? "sidebar-open" : ""}`}
        >
          <ShopSidebar
            sidebarVisible={sidebarVisible}
            toggleSidebar={toggleSidebar}
            handlelogin_click={toggleModal}
          />
        </div>
      )}

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalBody>
          <AuthModal
            type={auth_type}
            toggle={toggleModal}
            setType={setAuthType}
            onLoginSuccess={handleLoginSuccess}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
