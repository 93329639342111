import React from "react";
import { Outlet } from "react-router-dom";
import {
  Col,
  Row,
} from "reactstrap";
import ShopNavbar from "../Shop/ShopNavbar";
import Footer from "../Shop/footer/footer";
// import ItemDetails from "../Shop/ItemDetails";
// import { useWindowWidth } from "../utils/Helper";

function AppIndex(args) {
  // const [modal, setModal] = useState(true);

  // const toggle = () => setModal(!modal);
  // const width = useWindowWidth();

  return (
    <div>
      <Row className="m-0 p-0">
        <Col md={12} className="m-0 p-0 m">
          <ShopNavbar />
        </Col>
      </Row>
      {/* {width < 768 ? (
        <div className="mt-0 pt-1">
          <Modal
            centered
            backdrop
            fade
            isOpen={modal}
            toggle={toggle}
            {...args}
            style={{ marginTop: -200 }}
          >
            <ModalBody>For Easy Use Please Install our Mobile App</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  window.open("https://kasuwamall.com/kasuwa_mobile.apk", "_blank")
                  setModal(!modal)
                }}>
                Install
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : null} */}
      <Row className="m-0 p-0">
        <Col md={12}>
          <Outlet />
        </Col>

        <Footer />
      </Row>
    </div>
  );
}
export default AppIndex;
