import React from "react";
import styled from "styled-components";

const SkeletonContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0;
  padding: 16px;
  animation: pulse 1.5s infinite ease-in-out;

  @keyframes pulse {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
`;

const SkeletonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
`;

const SkeletonCol = styled.div`
  flex: ${(props) => props.flex || 1};
`;

const SkeletonImageLarge = styled.div`
  width: 100%;
  height: 45vh;
  background-color: #ddd;
  border-radius: 8px;
`;

const SkeletonImageSmall = styled.div`
  width: 100%;
  height: 10vh;
  background-color: #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const SkeletonText = styled.div`
  height: ${(props) => props.height || "16px"};
  background-color: #ccc;
  width: ${(props) => props.width || "100%"};
  margin-bottom: 8px;
  border-radius: 4px;
`;

const SkeletonLoader = () => (
  <SkeletonContainer>
    {/* Top Section */}
    <SkeletonRow>
      {/* Left Side Large Image */}
      <SkeletonCol flex={9}>
        <SkeletonImageLarge />
      </SkeletonCol>
      {/* Right Side Small Images */}
      <SkeletonCol flex={3}>
        {[...Array(4)].map((_, index) => (
          <SkeletonImageSmall key={index} />
        ))}
      </SkeletonCol>
    </SkeletonRow>

    {/* Details Section */}
    <SkeletonRow>
      {/* Left Side Large Image */}
      <SkeletonCol flex={6}>
        <SkeletonImageLarge />
      </SkeletonCol>

      {/* Product Details */}
      <SkeletonCol flex={6}>
        <SkeletonText width="70%" height="24px" /> {/* Title */}
        <SkeletonText width="40%" height="20px" /> {/* Price */}
        <SkeletonText width="50%" /> {/* Size */}
        <SkeletonText width="30%" /> {/* Quantity */}
        <SkeletonRow>
          {[...Array(5)].map((_, index) => (
            <SkeletonText
              key={index}
              width="20px"
              height="20px"
              style={{ borderRadius: "50%", marginRight: "4px" }}
            />
          ))}
        </SkeletonRow>
        <SkeletonText width="90%" height="60px" /> {/* Description */}
      </SkeletonCol>
    </SkeletonRow>

    {/* Bottom Section */}
    <SkeletonRow>
      {[...Array(10)].map((_, index) => (
        <SkeletonImageSmall key={index} style={{ width: "10%" }} />
      ))}
    </SkeletonRow>
  </SkeletonContainer>
);

export default SkeletonLoader;