import React, { useEffect, useState } from "react";
import { _get, globalColor } from "../../utils/Helper";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  DropdownMenu,
  Input,
  Spinner,
  Table,
} from "reactstrap";
import { ArrowLeft, Search } from "lucide-react";
import { useNavigate } from "react-router";
import "./shophomepage.css";
import defaultImg from "../../Images/No-Image-Placeholder.jpg";

const ShopList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [p_customers, setP_customers] = useState([]);
  const [loading, setLoading] = useState(false);

  const get_vendors = (role) => {
    setLoading(true);
    _get(
      "api/getapproveusers",
      (response) => {
        if (response.success) {
          setP_customers(response.results);
          setLoading(false);
        } else {
          alert("Error on getting users");
          setLoading(false);
        }
      },
      (error) => {
        alert("Error on getting users");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    get_vendors();
  }, []);

  const filteredCustomers = p_customers.filter(
    (customer) =>
      customer.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.phone.includes(searchQuery)
  );

  const handleView = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCustomer(null);
  };

  const navigate = useNavigate();

  const handleViewclick = (customer) => {
    navigate("vendor-view", { state: { customer } });
  };
  const handlegoback = () => {
    navigate(-1);
  };
  const handleshopclick = (customer) => {
    navigate("shop-view", { state: { customer } });
  };

  return (
    <div className="buy-by-shop">
      <Container>
        <div className="mb-2">
          <Button
            onClick={handlegoback}
            style={{
              background: "transparent",
              border: "2px solid #a52a2a",
              color: "#a52a2a",
            }}
          >
            <ArrowLeft /> Back
          </Button>
        </div>
        <div className="d-xl-flex d-lg-flex align-items-center justify-content-between p-3">
          <CardTitle className="cardtitle">Shop by Store</CardTitle>
          <div
            className="d-flex align-items-center justify-content-between bg-white rounded seach-div"
            style={{ border: "1px solid #f2eadb" }}
          >
            <div
              className="p-2"
              style={{
                background: globalColor.color2,
                borderRadius: "5px 0 0 5px",
              }}
            >
              <Search className="" />
            </div>
            <Input
              type="search"
              placeholder="Search..."
              className=""
              value={searchQuery}
            //   onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <Table
          responsive
          className="row row-cols-1 row-cols-sm-2 row-cols-lg-2 row-cols-xl-3 g-3"
        >
          {loading ? (
            <tr>
              <div className="text-center d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            </tr>
          ) : filteredCustomers.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center">
                No Shop found
              </td>
            </tr>
          ) : (
            filteredCustomers.map((customer, index) => (
              <tbody>
                <div
                  key={index}
                  onClick={() => handleshopclick(customer)}
                  className="text-decoration-none shoplist-div"
                >
                  <div className="shadow rounded">
                    <div className="bg-light rounded-top d-flex justify-content-center align-items-center py-3">
                      <img
                        src={
                          customer.image_urls
                            ? customer.image_urls.split(",")[0]
                            : defaultImg
                        }
                        alt={customer.username}
                        // className="shop-logo"
                        className="rounded-circle"
                        style={{ width: "12rem", height: "12rem" }}
                      />
                    </div>
                    <div className="px-4 py-2">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-sm text-muted-foreground md:inline">
                          {customer.shopname}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center text-center">
                        Dealers in all kind of : <br />{" "}
                        {customer.type_of_bussiness}
                      </div>
                      <hr />
                      <div className="d-block justify-content-center align-items-center text-center">
                        <div>Address :</div>
                        <div>{customer.shopaddress}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </tbody>
            ))
          )}
        </Table>
      </Container>
    </div>
  );
};

export default ShopList;
