/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Table, Button, Badge, Card, Row, Col } from "reactstrap";
import { Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { deleteCart, updateCart } from "../redux/action/shop";
import "./carttable.css";
import { useNavigate } from "react-router-dom";
import { globalColor, separator } from "../utils/Helper";
import { FaArrowLeft } from "react-icons/fa";

const CartTable = () => {
  const { carts } = useSelector((state) => state.shop); // Fetch cart items from Redux store
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteCartItem = (item) => {
    if (item.qty <= 1) {
      dispatch(deleteCart(item.product_id));
    } else {
      dispatch(updateCart({ product_id: item.product_id, qty: item.qty - 1 }));
    }
  };

  const removeCart = (item) => {
    console.log(item, "remove cart");
    dispatch(deleteCart(item.product_id));
  };

  const addCartItem = (item) => {
    dispatch(updateCart({ product_id: item.product_id, qty: item.qty + 1 }));
  };

  return (
    <div className="cart-container">
      <div className="d-lg-block d-sm-none table-div">
        <Table responsive className="shadow bg-light">
          <thead className="bg-dark">
            <tr>
              <th className="text-center">Item Details</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Item Price</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {carts.length ? (
              carts.map((item, idx) => (
                <tr key={idx}>
                  <td style={{ display: "flex", gap: "10px" }}>
                    <img
                      src={item.selectedImage}
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "10px",
                        border: "1px solid #542b2b",
                      }}
                    />
                    <p
                      style={{
                        textTransform: "capitalize",
                        // width: "50%",
                        // textAlign: "center",
                        // marginTop: 20,
                      }}
                    >
                      {item.name}
                    </p>
                  </td>
                  <td className="text-center">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ gap: "3px", marginTop: 10 }}
                    >
                      <Button
                        onClick={() => deleteCartItem(item)}
                        style={{
                          background: "#f2eadb",
                          color: "#542b2b",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                        disabled={item.qty <= 1}
                      >
                        -
                      </Button>
                      <Badge
                        className="p-3 mx-1"
                        style={{ background: globalColor.color1 }}
                      >
                        {item.qty}
                      </Badge>
                      <Button
                        onClick={() => addCartItem(item)}
                        style={{
                          background: "#f2eadb",
                          color: "#542b2b",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        +
                      </Button>
                    </div>
                  </td>
                  <td className="text-center">
                    <span style={{ fontWeight: "bold" }}>
                      ₦{separator(item.price)}
                    </span>
                    <br />
                    <small>₦{separator(item.price * item.qty)}</small>
                  </td>
                  <td className="text-center">
                    <Button onClick={() => removeCart(item)} color="danger">
                      <Trash />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No items in the cart
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {carts.length ? (
        carts.map((item, idx) => (
          <Card className="d-lg-none my-3 shadow border-0 p-2">
            <div key={idx}>
              <div
                className="mb-3 d-flex g-10"
                style={{
                  gap: "15px",
                }}
              >
                <div>
                  <img
                    src={item.selectedImage}
                    alt={`Item ${idx + 1}`}
                    style={{
                      width: "100px",
                      height: "120px",
                      borderRadius: "10px",
                      border: "1px solid #ddd ",
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {item.name}
                  </p>
                  <p style={{ fontSize: "14px", padding: 0, margin: 0 }}>
                    Sold by KASUWAMALL
                  </p>
                  <hr style={{ marginBottom: "-.01rem" }} />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-block">
                      <p className="p-0 m-0">Quantity:</p>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ gap: "3px", marginTop: 0 }}
                      >
                        <Button
                          onClick={() => deleteCartItem(item)}
                          style={{
                            background: "#f2eadb",
                            color: "#542b2b",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                          disabled={item.qty <= 1}
                          className="p-2 py-0"
                        >
                          -
                        </Button>
                        <Badge
                          className="p-2 mx-1"
                          style={{ background: globalColor.color1 }}
                        >
                          {item.qty}
                        </Badge>
                        <Button
                          onClick={() => addCartItem(item)}
                          style={{
                            background: "#f2eadb",
                            color: "#542b2b",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                          className="p-2 py-0"
                        >
                          +
                        </Button>
                      </div>
                    </div>
                    <div className="d-blocl jjustify-content-between align-items-center ">
                      <span style={{ fontWeight: "bold" }}>
                        ₦{separator(item.price)}
                      </span>
                      <br />
                      <small>₦{separator(item.price * item.qty)}</small>
                    </div>
                    {/* <p style={{ fontSize: "14px" }}>₦{item.price}</p> */}
                  </div>
                </div>
              </div>
              <div className="px-2 w-100 " style={{ width: "100%" }}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ gap: "10px" }}
                >
                  {/* <Button className="cont-bnt w-100">Save for letter</Button> */}
                  <Button
                    onClick={() => removeCart(item)}
                    className="cont-bnt w-50"
                  >
                    <Trash style={{ fontSize: "10px" }} /> Delete
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        ))
      ) : (
        <Card>
          <div className="text-center py-3">No items in the cart</div>
        </Card>
      )}
    </div>
  );
};

export default CartTable;
