import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCart, getStockList, updateCart } from "../redux/action/shop";
import { separator } from "../utils/Helper";
import Skeleton from "react-loading-skeleton";
import defaultImg from "../Images/No-Image-Placeholder.jpg";
import "./splide.css";
// Continue importing other images...

const CustomPrevArrow = (props) => (
  <button {...props} className="arrow left">
    <MdNavigateBefore size={30} />
  </button>
);

const CustomNextArrow = (props) => (
  <button {...props} className="arrow right">
    <MdNavigateNext size={30} />
  </button>
);

const settings = {
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  prevArrow: <CustomPrevArrow className="my-arrow" />,
  nextArrow: <CustomNextArrow className="my-arrow" />,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

function Splide({ content, loadingNew, src }) {
  const navigate = useNavigate();
  const { carts, stocks } = useSelector((s) => s.shop);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const skeleton = [1, 2, 3, 4, 5, , 7, 8];

  const getList = useCallback(() => {
    if (stocks.length < 1) {
      dispatch(getStockList(setLoading));
    }
  }, [stocks.length, dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleItemClick = (category, item) => {
    navigate(`/category/${category}/${item}`);
  };

  return (
    <div>
      <div className="productsliderwrapper">
        <Slider {...settings}>
          {loadingNew
            ? skeleton.map((idx) => {
                return (
                  <div key={idx} className="px-2 card--body">
                    <Skeleton
                      height={`100%`}
                      width={`100%`}
                      className="mb-2 card--image"
                    />
                    <Skeleton className="card--title mb-2" />
                    <Skeleton className="card--price" />
                  </div>
                );
              })
            : content?.map((item, index) => (
                <div
                  className="card--body px-3 my-2 py-2"
                  key={index}
                  onClick={() =>
                    handleItemClick(item.product_category, item.product_id)
                  }
                >
                  <div className="d-flex justify-content-between align-items-center card--image-container">
                    <img
                      src={
                        item.image_urls
                          ? item.image_urls?.split(",")[0]
                          : defaultImg
                      }
                      alt={item.name}
                      className="card--image"
                      loading="lazy"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "default_image_url";
                      }}
                    />
                  </div>

                  <p className="card--title mb-2">{item.name?.slice(0, 50)}</p>
                  <p className="card--price">
                    ₦ <b>{separator(item.price)}</b>
                  </p>
                </div>
              ))}
        </Slider>
      </div>
    </div>
  );
}

export default Splide;
