import React from "react";
import { FaStar } from "react-icons/fa";
import { Button, Row } from "reactstrap";
import styled from "styled-components";
import { separator } from "../utils/Helper";
import defaultImg from "../Images/No-Image-Placeholder.jpg";
import { updateCart } from "../redux/action/shop";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "./shop.css";

const ResponsiveCard = styled.div`
  // border: 1px solid #ddd;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  padding: 10px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
 

  @media (max-width: 768px) {
    display: grid;
    grid-template-row: repeat(1, 1fr));
    // gap: 10px;  
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
     margin-top: 15px;
  }
`;

const ResponsiveImage = styled.img`
  width: 37%;
  height: 130px;
  border-radius: 8px;
  margin: 6px;
  margin-right: 14px;
`;

const ResponsiveInfo = styled.div`
  margin-top: 10px;
`;

const ResponsiveName = styled.h3`
  font-size: 13px;
  font-weight: 600;
  margin: 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ResponsiveSalesCount = styled.div`
  font-size: 12px;
  color: #542b2b;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  display: flex;

  @media (max-width: 768px) {
    font-size: 11px; /* Slightly smaller font on mobile */
  }
`;

const ResponsiveBagde = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: #542b2b;
  font-weight: bold;
`;

const ResponsivePrice = styled.p`
  font-size: 12px;
  margin: 8px 0;
`;

const ResponsiveDiscountPrice = styled.span`
  font-weight: bold;
  color: #e60023;
`;

const ResponsiveFooter = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility 0s, opacity 0.3s ease;
  margin-left: 35%;
  margin-top: -55px;
`;

const RespondsiveAddToCartButton = styled.button`
  background-color: #542b2b;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  margin-left: -27px;
`;

const Card = styled.div`
  width: 100%;
  margin-top: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  z-index: 1;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 250px;
  border-radius: 8px;
`;

const Info = styled.div`
  //   padding-left: 20px;
  padding-top: 10px;
  // padding-bottom: 10px;
  // text-align: center;
`;

const Name = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SalesCount = styled.div`
  font-size: 12px;
  color: #542b2b;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  display: flex;

  @media (max-width: 768px) {
    font-size: 11px; /* Slightly smaller font on mobile */
  }
`;

// const Button = styled.button`
//   margin: 8px 0;
//   padding: 8px 16px;
//   border: none;
//   border-radius: 4px;
//   font-size: 14px;
//   cursor: pointer;
// `;

const AddToCartButton = styled.button`
  background-color: transparent;
  color: #542b2b;
  border: 1px solid #542b2b;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  transition: color 0.5s;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: #542b2b;
    height: 150px;
    width: 200px;
    border-radius: 50%;
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }
    &:hover:before {
      top: -30px;
  left: -30px;
    }
  &:hover {
    color: #fff;
  }
  }
  @media (max-width: 768px) {
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  }
`;

const Bagde = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: #542b2b;
  font-weight: bold;
`;

const SecondPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  margin-top: 10px;
  left: 0;
  bottom: 0;
  //   margin-bottom: 10px;
  //   martop: -7px;
`;

const NairaSign = styled.span`
  font-size: 15px;
  margin-top: 7px;
  font-weight: bold;
  margin-right: 5px;
`;

const NewDiscount = styled.span`
  font-size: 22px;
  color: #542b2b;
  font-weight: bold;
`;

const Details = styled.p`
  font-size: 14px;
  color: #777;
  margin-bottom: -4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 12px; /* Smaller font on mobile */
  }
`;

const Footer = styled.div`
  // position: absolute;
  bottom: 0;
  width: 100%;
  // background-color: #fff;
  padding: 8px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden; /* Hide the footer by default */
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease; /* Smooth transition */

  ${Card}:hover & {
    visibility: visible; /* Show the footer when the card is hovered */
    opacity: 1;
  }

  @media (max-width: 768px) {
    visibility: visible; /* Show the footer on mobile */
    opacity: 1;
    position: static;
    margin-top: 0px;
  }
`;

const ProductCardData = ({
  product,
  openQty,
  handleAddToCart,
  handleQuantityChange,
  quantity,
  handleItemClick,
}) => {
  const { carts } = useSelector((state) => state.shop);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addCartItem = (item) =>
    dispatch(updateCart({ product_id: item.product_id, qty: item.qty + 1 }));
  const deleteCart = (item) =>
    dispatch(updateCart({ product_id: item.product_id, qty: item.qty - 1 }));

  const selected = carts.find((a) => a.product_id === product.product_id);
  return (
    <Row className="m-0 p-0 ">
      {/* {JSON.stringify(product)} */}
      <Card className="card-hover show pb-3 pt-2 mx-2" style={{ gap: "10px" }}>
        <div onClick={handleItemClick}>
          <Image
            src={
              product.image_urls ? product.image_urls.split(",")[0] : defaultImg
            }
            alt={product.name}
          />
          <Info>
            <Name>
              {/* {product.name} */}
              {product.name?.slice(0, 16) +
                (product.name?.length > 16 ? "..." : "")}
            </Name>
            <SalesCount>
              {[0, 1, 2, 3, 4].map((rating) => (
                <FaStar
                  size={13}
                  key={rating}
                  aria-hidden="true"
                  color={product.product_rating > rating ? "brown" : "#e0e0e0"}
                />
              ))}
              <Bagde>200+ sold</Bagde>
            </SalesCount>
            <Details>
              {product.description?.slice(0, 40) +
                (product.description?.length > 40 ? "..." : "")}
            </Details>
            <SecondPriceContainer>
              <NairaSign>₦</NairaSign>
              <NewDiscount>{separator(product.price)}</NewDiscount>
            </SecondPriceContainer>
          </Info>
        </div>
        <Footer>
          <div className="d-flex justify-content-center align-items-center">
            {openQty ? (
              <div className="d-flex">
                <div className="d-flex text-center inc_dec_btn_div rounded">
                  <Button
                    className="inc_dec_btn"
                    onClick={() => deleteCart(selected)}
                    disabled={selected.qty === 1}
                  >
                    -
                  </Button>
                  <div
                    className="p-2 py-1 mx-2 text-light rounded"
                    style={{ background: "#542b2b" }}
                  >
                    {selected.qty}
                  </div>
                  <Button
                    className="inc_dec_btn"
                    onClick={() => addCartItem(selected)}
                  >
                    +
                  </Button>
                </div>
              </div>
            ) : (
              <AddToCartButton onClick={() => handleAddToCart(product)}>
                Add to Cart
              </AddToCartButton>
            )}
          </div>
        </Footer>
      </Card>
      <ResponsiveCard className="card-hover show">
        <div
          onClick={handleItemClick}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <ResponsiveImage
            src={
              product.image_urls ? product.image_urls.split(",")[0] : defaultImg
            }
            alt={product.name}
          />
          <ResponsiveInfo>
            <ResponsiveName>{product.name}</ResponsiveName>
            <ResponsiveSalesCount>
              {[0, 1, 2, 3, 4].map((rating) => (
                <FaStar
                  size={13}
                  key={rating}
                  aria-hidden="true"
                  color={product.product_rating > rating ? "brown" : "#e0e0e0"}
                />
              ))}
              <ResponsiveBagde>200+ sold</ResponsiveBagde>
            </ResponsiveSalesCount>
            <ResponsivePrice>
              <ResponsiveDiscountPrice>
                NGN{product.price}
              </ResponsiveDiscountPrice>
            </ResponsivePrice>
          </ResponsiveInfo>
        </div>
        <ResponsiveFooter>
          {openQty ? (
            <div className="quantity-controls d-flex align-items-center">
              <button
                className="quantity-btn btn btn-sm btn-outline-danger"
                onClick={() => handleQuantityChange(-1)}
                disabled={quantity === 1}
              >
                -
              </button>
              <span className="quantity-display mx-2">{quantity}</span>
              <button
                className="quantity-btn btn btn-sm btn-outline-success"
                onClick={() => handleQuantityChange(1)}
              >
                +
              </button>
            </div>
          ) : (
            <AddToCartButton
              onClick={handleAddToCart}
              style={{ padding: "5px 10px", top: 0, right: "20px" }}
            >
              Add to Cart
            </AddToCartButton>
          )}
        </ResponsiveFooter>
        {/* </div */}
      </ResponsiveCard>
    </Row>
  );
};

export default ProductCardData;

{
  /* <div onClick={handleItemClick}></div> */
}
