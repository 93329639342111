// src/components/ProductDetails.js

import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup, Input } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap";
import "./ShopIndex.css";
import {
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaTruck,
  FaRecycle,
  FaArrowLeft,
} from "react-icons/fa";
import pic1 from "../Images/vitamins2.webp";
import pic2 from "../Images/vitamin.webp";
import pic3 from "../Images/vitamin3.webp";
import pic4 from "../Images/vitamin4.webp";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// import { Button } from "reactstrap";
import { addCart } from "../redux/action/shop";
import { _get, separator, useWindowWidth } from "../utils/Helper";
import ProductDet from "./ProductDet";

const ProductDetails = () => {
  // const { state } = useLocation();
  // const { item } = state;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const handleAddToCart = () => {
    dispatch(addCart({ ...data, qty: quantity }));
    navigate("/cart");
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  // array that stores the product pictures
  // const items = [
  //   { src: pic1, altText: 'Front view', key: 1 },
  //   { src: pic2, altText: 'Side view', key: 2 },
  //   { src: pic3, altText: 'Back view', key: 3 },
  //   { src: pic4, altText: 'Top view', key: 4 },
  // ];
  const { id } = useParams();
  const { stocks } = useSelector((state) => state.shop);
  //defining the product to be inserted in the cart
  //const product = stocks.find((item) => item.item_code === id);
  const [quantity, setQuantity] = useState(1);
  // const [currentImage, setCurrentImage] = useState(data.image_urls[0]);

  // value that stores the quantity
  const [carts, setCarts] = useState([]);
  //methods that handle the add quantity button
  // const handleQuantityChange = (amount) => {
  //   setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  // }

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  // method that will handle the order quantity
  // const handleAddToCart = () => {
  //   setCarts((prevCarts) => [...prevCarts, { qty: quantity }]);
  // };

  useEffect(() => {
    setLoading(true);
    setError(null);

    _get(
      `api/products/${id}`,
      (res) => {
        setData(res.result);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );
  }, [id]);

  const width = useWindowWidth();

  // methed for the product image view
  // const handleImageClick = (item) => {
  //   setCurrentImage(item);
  // };

  // if (!product) {
  //   return <p className='mt-5 pt-5'>Product not found</p>;
  // }

  return (
    <>
      {/* <Card className="p-4" style={{ width: "100%", marginTop: "2.8rem" }}>
        <h4 className="left m-0" style={{ fontSize: "2rem" }}>
          Vitamins and Supplements
        </h4>
      </Card> */}

      <Container style={{ marginTop: "8rem" }}>
        <Button
          onClick={goBack}
          className="cont-bnt mb-3 d-flex align-items-center"
        >
          <FaArrowLeft className="me-2" />
          Back
        </Button>
        <Row>
          {/* {JSON.stringify(data)} */}
          <Col md={8}>
            <Card className="mb-4 card" style={{ height: "100vh" }}>
              <Row noGutters>
                <Col
                  md={6}
                  className="pt-5"
                  style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                >
                  {/* {JSON.stringify(data)} */}
                  {/* hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh */}
                  <CardImg
                    src={data.image_urls}

                    // data.image_urls[0]||
                    // 'placeholder-image-path'}
                    // alt={data.product_name}
                    // alt="Ashwagandha 1500mg - 90 Tablets"
                  />
                  {/* section for other images */}
                  <div
                    className="other-images mt-3"
                    style={{ paddingLeft: "1rem" }}
                  >
                    {/* {data.image_urls.map((item) => (
                      <img
                        key={item.key}
                        src={item.src}
                        alt={item.altText}
                        onClick={() => handleImageClick(item)}
                        className={`thumbnail ${
                          currentImage.key === item.key ? "active" : ""
                          }`}
                        style={{
                          width: "60px",
                          margin: "0 5px",
                          cursor: "pointer",
                          border:
                            currentImage.key === item.key
                              ? "2px solid orange"
                              : "1px solid #EAEDF8",
                        }}
                      />
                    ))} */}
                    {/* images */}
                  </div>
                </Col>
                <Col md={6}>
                  {/* i changed the cardbody to div again */}
                  <div className="d-flex flex-column end">
                    <CardTitle tag="h2" className="pt-5">
                      {data.product_name}
                    </CardTitle>
                    <CardText>
                      Description: {data.product_description}
                      {/* Product Code: <strong>6424670</strong> */}
                    </CardText>
                    <hr style={{ width: "90%", opacity: ".1" }}></hr>
                    <div className="product-price">
                      <span className="discounted-price">
                        Price: ₦{separator(data.product_price)}
                        {/* ₦16,958 */}
                      </span>
                      {/* <span className="original-price">₦19,700</span>
                      <span className="savings">You save ₦2,742</span> */}
                    </div>
                    <hr style={{ width: "90%", opacity: ".1" }}></hr>
                  </div>
                  <span>Quantity: </span>
                  <div className="quantity-selector mt-3">
                    <Button
                      className="quantity-btn"
                      onClick={() => handleQuantityChange(-1)}
                    >
                      -
                    </Button>
                    <span className="quantity-display">{quantity}</span>
                    <Button
                      className="quantity-btn"
                      onClick={() => handleQuantityChange(1)}
                    >
                      +
                    </Button>
                  </div>
                  <hr style={{ width: "90%", opacity: ".1" }}></hr>
                  {/* <div className="bulk-purchase mt-2">
                      <p>Call us for Bulk Purchases: <strong>07080635700</strong></p>
                    </div> */}

                  <Button
                    onClick={handleAddToCart}
                    style={{
                      backgroundColor: "#36454F",
                      color: "white",
                      paddingLeft: "5rem",
                      paddingRight: "5rem",
                      fontSize: "22px",
                      fontWeight: "600",
                      borderRadius: "0",
                    }}
                  >
                    Add To Cart
                  </Button>
                  <div className="pickup-info mt-3">
                    <p>
                      <i className="fas fa-store"></i> Pickup & Pay on
                      Collection Available
                    </p>
                  </div>

                  <div className="share-with-friends mt-3">
                    <p>Share With Friends:</p>
                    <Link to="#">
                      <FaFacebook className="share-iconF" />
                    </Link>
                    <Link to="#">
                      <FaTwitter className="share-iconT" />
                    </Link>
                    <Link to="#">
                      <FaWhatsapp className="share-iconW" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <CardHeader tag="h5" className="bg-white">
                {" "}
                Delivery and Returns:
              </CardHeader>
              <CardBody>
                <FaTruck className="truck" style={{ fontSize: "30px" }} />{" "}
                <span
                  style={{
                    fontSize: "20px",
                    fontStyle: "bold",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Delivery{" "}
                </span>
                <CardText>Estimated delivery time 1-9 business days</CardText>
                <CardText>Express Delivery Available</CardText>
                <CardText>
                  For Same-Day-Delivery: Please place your order before 11AM
                </CardText>
                <hr style={{ opacity: ".1" }}></hr>
                <FaRecycle
                  className="truck"
                  style={{ fontSize: "30px" }}
                />{" "}
                <span
                  style={{
                    fontSize: "20px",
                    fontStyle: "bold",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Return Policy{" "}
                </span>
                <CardText>
                  Next-Day-Delivery: Orders placed after 11AM will be delivered
                  the next day
                </CardText>
                <CardText>Note: Availability may vary by location</CardText>
                <a href="#terms" className="card-link">
                  Terms and conditions apply
                </a>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle tag="h3"> Sellers Informationns </CardTitle>
                <CardText>Estimated delivery time 1-9 business days</CardText>
                <CardText>Express Delivery Available</CardText>
                <CardText>
                  For Same-Day-Delivery: Please place your order before 11AM
                </CardText>
                <CardText>
                  Next-Day-Delivery: Orders placed after 11AM will be delivered
                  the next day
                </CardText>
                <CardText>Note: Availability may vary by location</CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ProductDet />
    </>
  );
};

export default ProductDetails;
