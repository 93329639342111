import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addCart, getStockList, updateCart } from "../redux/action/shop";
import { _get, globalColor, separator, useWindowWidth } from "../utils/Helper";
import { FaArrowLeft, FaRecycle, FaStar, FaTruck } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import defaultImg from "../Images/No-Image-Placeholder.jpg";
import "./carttable.css";
import ProductCardData from "./SecondPRoductCard";
import CustomAddtoCard from "./shopHomepage/CustomAddtoCard";

export default function Category() {
  const navigate = useNavigate();
  const { carts, stocks } = useSelector((s) => s.shop);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { category } = useParams();
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [quantity, setQuantity] = useState({});
  const [openQty, setOpenQty] = useState({});

  const skeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1];

  useEffect(() => {
    setLoading(true);
    _get(
      `api/get-product-by-ctgry_id?ctgry_id=${category}`,
      (res) => {
        setData(res.result[0]);
        setLoading(false);
        setMessage(res.message);
      },
      (err) => {
        setError(err);
      }
    );
  }, [category]);

  useEffect(() => {
    setSortedData(
      data?.filter(
        (product) =>
          product.prod_status === "available" || product.product_quantity
      )
    );
  }, [data]);

  const handleItemClick = (item) => {
    navigate(`/category/${category}/${item.product_id}`);
  };

  const width = useWindowWidth();
  const userDetails = useSelector((userDetails) => userDetails);
  const goBack = () => {
    navigate(-1);
  };

  const handleAddToCart = (product) => {
    const selectedImage =
      product.image_urls && product.image_urls.includes(",")
        ? product.image_urls.split(",")[0]
        : product.image_urls;

    dispatch(
      addCart({
        ...product,
        qty: quantity[product.product_id] || 1,
        selectedImage: selectedImage || "default-placeholder.png",
      })
    );

    // Update `openQty` for this product
    setOpenQty((prevState) => {
      const updatedState = { ...prevState };

      // Set the clicked product to true, others to false
      Object.keys(updatedState).forEach((key) => {
        updatedState[key] = true;
      });

      updatedState[product.product_id] = true;

      return updatedState;
    });
  };

  const handleQuantityChange = (productId, amount) => {
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [productId]: Math.max(1, (prevQuantity[productId] || 1) + amount), // Ensure quantity is at least 1
    }));
  };

  return (
    <div style={{ marginTop: 150, padding: "20px" }}>
      {loading ? (
        <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-5 row-cols-xl-6 g-3">
          {skeleton.map((holder, index) => (
            <div className="px-1" key={index}>
              <div className="shadow rounded p-0">
                <div className="ratio ratio-1x1 bg-light overflow-hidden rounded-top">
                  <Skeleton className="h-100 w-100" />
                </div>
                <div className="px-2">
                  <Skeleton className="my-3 w-75" />
                  <Skeleton className="ps-2 w-50" />
                  <Skeleton className="mt-1 w-50" height={10} />
                  <Skeleton className="my-2 w-25" />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : data?.length > 0 ? (
        <div
          className="category-g-div"
          // style={{ gap: "10px" }}
        >
          {JSON.stringify(category.ctgry_name)}
          {data.map((item) => (
            <ProductCardData
              key={item.product_id}
              product={item}
              openQty={openQty[item.product_id] || false}
              quantity={quantity[item.product_id] || 1}
              handleAddToCart={() => handleAddToCart(item)}
              handleQuantityChange={(amount) =>
                handleQuantityChange(item.product_id, amount)
              }
              handleItemClick={() => handleItemClick(item)}
            />
          ))}
          {/* kjghslbfukykejhrfbkehjbfvkdjzhfxbkejaksfbcujhszlkbfvkZHSjhlbckuWAshj */}
        </div>
      ) : (
        <Alert
          style={{
            backgroundColor: globalColor.color3,
            borderColor: globalColor.color3,
            color: globalColor.color2,
          }}
        >
          {message}
        </Alert>
      )}
    </div>
  );
}
